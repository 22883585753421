import React from 'react'
import styled from 'styled-components'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import Menu from '../components/Menu'
import Contact from '../components/Contact'

const Section = styled.section`
  width: 100%;
  height: 400px;
  margin: 0 auto;
  text-align: center;
`

const NotFoundPage = () => (
  <Layout>
    <SEO title='404: Not found' />
    <Menu />
    <Section>
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      <p>သင်ရှာနေသော စာမျက်နှာ ဤ website တွင် မရှိပါ။</p>
    </Section>
    <Contact />
  </Layout>
)

export default NotFoundPage
